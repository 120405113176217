import { useState, useEffect } from "react";
import ReopenIcon from '@mui/icons-material/Replay';
import {
  useDataProvider,
  useRedirect,
  useNotify,
  TextField,
  TopToolbar,
  FunctionField,
  SimpleShowLayout,
  Button,
  WithRecord,
  ListButton,
  EditButton,
  DeleteWithConfirmButton,
  Title,
  ReferenceField,
  Show,
  List,
  useRecordContext,
  ReferenceManyField,
  RichTextField,
  FileField,
  Labeled,
  ArrayField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  usePermissions,
} from "react-admin";
import Grid from "@mui/material/Grid";
import { NewComment } from "./NewComment";
import ReopenRequest from "./ReopenRequest";
import {ReopenApproval} from "./ReopenApproval";
import { Close } from "@mui/icons-material";
import { CommentsIterator } from "./CommentsIterator";
import ShowAttachments from "./ShowAttachments";
import {
  AssigneeField,
  PreparerField,
  CaseStatusField,
  DateField,
} from "../erpd";
import { useSearchParams } from "react-router-dom";

const CaseToolbar = () => {
  // const { reset } = useFormContext();
  // const notify = useNotify();
  return (
    <div>
      <Button label="Complete Case" variant="contained" />
      <Button label="Close Case" variant="contained" />
      <Button label="Re-Open Case" variant="contained" />
      {/* <SaveButton label="Save" />
      <SaveButton
        label="Save and add"
        mutationOptions={{
          onSuccess: () => {
            // notify("Element created");
            // reset();
          },
        }}
        type="button"
        variant="text"
      /> */}
    </div>
  );
};

const DocField = (props) => {
  const case_ = useRecordContext();
  if (case_.drawing_id) {
    return (
      <ReferenceField reference="drawings" source="drawing_id" link="show">
        <FunctionField
          render={(x) => (
            <span>
              {x.drawingType.name} ({x.version})
            </span>
          )}
        />
      </ReferenceField>
    );
  }
  if (case_.minute_id) {
    return (
      <ReferenceField reference="minutes" source="minute_id" link="show">
        <FunctionField render={(x) => <span>{x.title}</span>} />
      </ReferenceField>
    );
  }
  return <span>-</span>;
};

function canEditCase(record, permissions) {
  if (permissions.isProjectAdmin(record.project_id)) {
    return true; // Project admin always has permission
  }
  if (record.createdBy._id != permissions._id) {
    return false; // Immediately return false if the user is not the creator
  } else if (record.createdBy._id == permissions._id) {
    return true; // If the user is the creator, they can edit
  }
  if (record.status == 'Open' || record.status == 'Overdue' || record.status == 'Overdued') {
    return true; // Allow edit if status is Open, Overdue, or Overdued
  }
  return false; // Default return if none of the conditions are met
}


function canDeleteCase(record, permissions) {
  if (permissions.isProjectAdmin(record.project_id))
    return true;
  if (record.createdBy._id == permissions._id)
    return true;
  return false;
}

export const CaseShow = (props) => {
  const { isLoading, permissions } = usePermissions();
  const [ reopenClicks, setReopenClicks ] = useState(0);
  const [ searchParams, setSearchParams ] = useSearchParams();
  const reopen = searchParams.get('reopen');

  return (!isLoading &&
  <Show
    {...props}
    // title={<WithRecord render={(x) => <span>Case: {x.subject}</span>} />}

    actions={
      <TopToolbar style={{ minHeight: 20 }}>
        {!props.handleClose && (
          <WithRecord
            render={(record) => <ListButton to={`/projects/${record.project_id}/show`} />}
          />
        )}
        <WithRecord
          render={(record) => canEditCase(record, permissions)? <EditButton />: null}
        />
        <WithRecord
          render={(record) => (record.status=='Completed' || record.status=='Closed') ? <Button label="Re-Open" onClick={()=>setReopenClicks(n=>n+1)}><ReopenIcon /></Button>: null}
        />
        {props.handleClose && props.deleteAnnotationById && (
          <WithRecord
            render={(record) => canDeleteCase(record, permissions) && (
              <DeleteWithConfirmButton
                redirect={false}
                mutationOptions={{
                  onSettled: (data, error) => {
                    if (error) return;
                    props.handleClose();
                    // console.log(data.annotations[0]._id);
                    props.deleteAnnotationById(data.annotations[0]._id);
                  },
                }}
              />
            )}
          />
        )}
        {props.handleClose && (
          <Button onClick={props.handleClose} startIcon={<Close />} />
        )}
      </TopToolbar>
    }
  >
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <SimpleShowLayout>
          <TextField source="subject" style={{ fontWeight: "bold" }} />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <ReferenceField
            source="project_id"
            reference="projects"
            link="show"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <TextField source="no" label="Case No." />
          {/* <FunctionField label="Subject" render={record => (<><i>#{record.no}</i> {record.subject}</>)} /> */}
        </SimpleShowLayout>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <ReferenceField
            label="Case Type"
            reference="dropdown_names"
            source="case_type_id"
            link={false}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <CaseStatusField />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <PreparerField />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <DateField source="createdAt" />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <AssigneeField />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <DateField source="due_date" showTime={false} />
        </SimpleShowLayout>
      </Grid>
      <WithRecord render={(x) => (x.observers && x.observers.length > 0) &&
      <Grid item xs={12} sm={12} md={12}>
        <SimpleShowLayout>
        <ReferenceArrayField reference="role_names" source="observers" label="Previous Assignee(s)">
        <SingleFieldList linkType={false}>
          <ChipField source="name" />
      </SingleFieldList>
        </ReferenceArrayField>
        </SimpleShowLayout>
      </Grid>
      } />
      <Grid item xs={12} sm={12} md={12}>
        <SimpleShowLayout>
          <Labeled label="Message">
            <RichTextField source="message" label="Message" />
          </Labeled>
          <WithRecord
            render={(x) => (
              <ShowAttachments images={x.images} files={x.attachments} />
            )}
          />
        </SimpleShowLayout>
      </Grid>

      {/*      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <FileField source="attachments" src="src" title="originalname" target="preview" sx={{
            '& ul': {
              margin:0,
              paddingLeft:0,
              '& li': {
                display:'inline',
                marginRight:10
              }
            }
          }} />
        </SimpleShowLayout>
      </Grid>
*/}
      {/*<Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <DocField label="Reference" />
        </SimpleShowLayout>
      </Grid>*/}
    </Grid>

    {/* <CaseToolbar /> */}
    <ReferenceManyField
      target="case_id"
      reference="comments"
      perPage={1000}
      sort={{ field: "createdAt", order: "ASC" }}
      label={false}
    >
      <SimpleShowLayout>
      <WithRecord render={(x) => <CommentsIterator readonly={(x.status != "Open" && x.status != "Overdued")} showStatus reference="comments" /> } />
       
      </SimpleShowLayout>
      <WithRecord render={(x) => (x.status === "Open" || x.status === "Overdued")? <NewComment />: <></>} />
      {/* <NewComment /> */}
    </ReferenceManyField>
    <ReopenApproval id={reopen} />
    <ReopenRequest clicks={reopenClicks} />
  </Show>
  );
}
