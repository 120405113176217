import {
  Create,
  SimpleForm,
  DateInput,
  SelectInput,
  useNotify,
  useRecordContext,
  useRedirect,
  SimpleShowLayout,
  ReferenceField,
  TextInput,
  ReferenceInput,
  required,
  useCreate,
  FileInput,
  FileField,
  Button,

} from "react-admin";
import { Box } from "@mui/material";
import { useQueryClient } from "react-query";
import { Close } from "@mui/icons-material";
import { useState } from "react";
import {
  caseStatusEnum,
  CreateTag,
  CreateAssignee,
  CustomRichTextEditor,
} from "../erpd";
import { API_URL } from "../config/domain";
import { useFormContext } from "react-hook-form";
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
/**
 * count how many date from today to specified date
 * @param {string} yyyymmdd local date as yyyy-mm-dd
 * @returns {number} count of days, 0 => today, -1 => yesterday, 1 => tomorrow
 */
function howManyDaysFromToday(yyyymmdd) {
  const now = new Date();
  const localToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const localDate = new Date(yyyymmdd);
  return (localDate - localToday) / 86400000;
}

//form validation function
const caseCreateValidation = async (values) => {
  let errors;

  errors = {};

  //assignee checks
  if (!values.assignee) errors.assignee = "Assignee must not be empty";

  //subject checks
  if (!values.subject) errors.subject = "Subject must not be empty";
  else if (values.subject.length < 1)
    errors.subject = "Subject must not be empty";

  //case type checks
  if (!values.case_type_id) errors.case_type_id = "Case type must not be empty";

  //due date checks
  if (!values.due_date) errors.due_date = "Due date must not be empty";
  if (howManyDaysFromToday(values.due_date) < 0)
    errors.due_date = "Due date must be greater than or equal to today";

  if (values.drawing_id) {
    // validate drawing is not taken by another case
    const annotation_id = localStorage["erpd/active-annot-id"];
    if (!annotation_id) {
      errors.annotation = "Please select annotation";
      if (Object.keys(errors).length === 1) alert(errors.annotation);
    }
  }
  // //status checks
  // if (!values.status || values.status === "")
  //   errors.status = "Status must not be empty";

  return errors;
};

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const NewCaseCreateForm = () => {
  const record = useRecordContext();
  const { getValues, setValue } = useFormContext()
  const [showImagePreview, setShowImagePreview] = useState(false)
  const [imageBlob, setImageBlob] = useState("")
  const [imageFile, setImageFile] = useState()
  const [imageName, setImageName] = useState("")
  document.onpaste = async () => {
    const items = await navigator.clipboard.read().catch((err) => {
      console.error(err);
    });

    for (let item of items) {
      for (let type of item.types) {
        if (type.startsWith("image/")) {
          const imageBlob = await item.getType(type)
          const blobUrl = URL.createObjectURL(imageBlob)
          const fileName = makeid(10) + '.jpg';
          let file = new File([imageBlob], fileName, { type: "image/jpeg" });
          setImageBlob(blobUrl);
          setImageFile(file)
          setImageName(fileName)
          setShowImagePreview(true)
          // const newValue = {
          //   rawFile: file,
          //   title: fileName,
          //   src: blobUrl,
          // }
          // if (!!value) {

          //   value.push(
          //     newValue
          //   )
          // } else {
          //   value = [
          //     newValue
          //   ]
          // }
          // setValue("files", value)
          return true;
        }
      }
    }
  }
  return <>
    <Modal
      sx={{ height: '100%', width: '100%' }}

      open={showImagePreview}
      onClose={() => {
        setShowImagePreview(false)
      }}
    >
      <div style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        {
          //TODO: Put image preview here
        }
        <Paper
          style={{ maxWidth: "50%", padding: 8 }}
        >
          <h2>Preview</h2>
          <img style={{ maxWidth: "100%" }} src={imageBlob} />
          <div style={{ display: "flex", justifyContent: "end" }}>

            <Button
              onClick={() => {
                let value = getValues("attachments")
                const newValue = {
                  rawFile: imageFile,
                  title: imageName,
                  src: imageBlob,
                }
                if (!!value) {

                  value.push(
                    newValue
                  )
                } else {
                  value = [
                    newValue
                  ]
                }
                setValue("attachments", value)
                setShowImagePreview(false)
              }}
            >Paste</Button>
            <Button onClick={() => {
              setShowImagePreview(false)
            }}>Cancel</Button>
          </div>
        </Paper>
      </div>
    </Modal>
    <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
      <Box flex={2} mr={{ xs: 0, sm: "1em" }}>
        <TextInput source="subject" validate={required()} fullWidth />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <ReferenceInput
          label="Case Type"
          source="case_type_id"
          reference="case_types"
          filter={{ project_id: record.project_id }}
        >
          <SelectInput
            optionValue="id"
            optionText="name"
            validate={required()}
            create={<CreateTag resource="case_types" />}
            createLabel="(Create...)"
          ></SelectInput>
        </ReferenceInput>
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <DateInput source="due_date" validate={required()} />
      </Box>
      <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
        <ReferenceInput
          label="Assignee"
          source="assignee"
          reference="assignees"
          filter={{ project_id: record.project_id }}
        >
          <SelectInput
            optionValue="id"
            optionText="name"
            validate={required()}
            create={<CreateAssignee resource="assignees" />}
            createLabel="(Add Member...)"
          ></SelectInput>
        </ReferenceInput>
      </Box>
    </Box>

    {/* <AutocompleteInput
            source="status"
            choices={caseStatusEnum}
            optionValue="name"
            defaultValue={caseStatusEnum[0].name}
          /> */}
    <CustomRichTextEditor source="message" />
    <FileInput source="attachments" multiple>
      <FileField source="src" title="title" target="preview" />
    </FileInput>
  </>
}

const CaseCreateForm = ({ onCreated }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const queryClient = useQueryClient();

  const onSuccess = (data) => {
    queryClient.invalidateQueries("cases");
    if (data.drawing_id || data.minute_id) {
      if (onCreated) onCreated();
    } else {
      redirect(`/projects/${data.project_id}/show`);
    }
    notify("New case is created.", { type: "success" });
  };

  const [create] = useCreate();
  const postSave = async (data) => {
    // Remove fontSize from data before sending to the server
    console.log(data)
    const { fontSize, ...postData } = data;
    // Update the data object
    data = postData;

    if (data.annotations && data.annotations.length > 0) {
      console.log(data.annotations[0])
      // return
      const activeAnnotId = data.annotations[0].uuid;
      const fetchRes = await fetch(`${API_URL}/annotations/${activeAnnotId}`, {
        credentials: "include",
      });
      const annotation = await fetchRes.json();
      console.log(annotation)
      // //check if annotation is taken
      // try {
      //   const fetchResCases = await fetch(`${API_URL}/cases/?annotations._id=${activeAnnotId}`, { credentials: 'include' })
      //   const existingCases = await fetchResCases.json();
      //   if (existingCases.doc.length > 0)
      //     return alert("Annotation taken by another case");
      // } catch (error) {
      //   console.error(error);
      //   return alert("Unable to fetch cases for validation");
      // }

      data.annotations = [{ ...annotation }];
    }
    console.log(data)
    await create("cases", { data }, { returnPromise: true, onSuccess });
  };

  return (
    <div>
      <SimpleForm validate={caseCreateValidation} onSubmit={postSave}>
        <NewCaseCreateForm />
      </SimpleForm>
    </div>
  );
};

export const CaseCreate = (props) => {
  const { record, onCreated } = props;

  return (
    <Create resource="cases" record={record}>
      <>
        {/*<div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {props.handleClose && <Button onClick={props.handleClose} startIcon={<Close />} />}
        </div>*/}
        <SimpleShowLayout>
          <ReferenceField
            source="project_id"
            reference="projects"
            link="show"
          />
        </SimpleShowLayout>
        <CaseCreateForm onCreated={onCreated} />
      </>
    </Create>
  );
};
