import {
  DeleteButton,
  SimpleForm,
  Edit,
  WithRecord,
  useRecordContext,
  SimpleShowLayout,
  ReferenceField,
  TopToolbar,
  ShowButton,
  ListButton,
  FileInput,
  FileField,
  SaveButton,
  Toolbar,
} from "react-admin";
import { TextInput, CustomRichTextEditor } from "../erpd";

function SaveDeleteToolbar() {
  const record = useRecordContext();
  return (
    <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      <DeleteButton
        translateOptions={{ id: record.subject }}
        // redirect={`/minutes/${record.minute_id}/show?notefilter=${encodeURIComponent("{}")}`}
        redirect={`/projects/${record.project_id}/show/notes`}
      />
    </Toolbar>
  );
}

export function NoteEdit() {
  return (
    <Edit
      redirect={(resource, id, data) => {
        return `projects/${data.project_id}/show/notes`;
        // const filter = "notefilter=" + encodeURIComponent(`{"no": ${data.no}}`) + `&pg=${data.annotations?.at(0)?.page}`;
        // const filter = "notefilter=" + encodeURIComponent("{}"); // trigger listing all notes
        // return `minutes/${data.minute_id}/show?${filter}`;
      }}
      mutationMode="pessimistic"
      actions={
        <TopToolbar>
          <WithRecord
            render={(record) => (
              <ListButton to={`/projects/${record.project_id}/show/notes`} />
            )}
          />
          <ShowButton />
        </TopToolbar>
      }
    >
      <SimpleShowLayout>
        <ReferenceField source="project_id" reference="projects" link="show" />
      </SimpleShowLayout>
      <SimpleForm toolbar={<SaveDeleteToolbar />}>
        <TextInput source="subject" fullWidth />
        <CustomRichTextEditor source="message" />
        <FileInput source="attachments" multiple>
          <FileField source="src" title="title" target="preview" />
        </FileInput>
      </SimpleForm>
    </Edit>
  );
}
